import React from 'react'
import '../components/Header.css'

const Header = () => {

   


    function handleCallNow() {
        const phoneNumber = "92263 41599";
        window.location.href = `tel:+${phoneNumber}`;
    }




    return (
        <>
            <section className="as_header_wrapper" >
                <div className="as_logo">                
                    <a href="/" ><img src="assets/images/astro.png" alt="" /></a>
                </div>
                <div className="as_header_detail">
                   
                    <div className="as_menu_wrapper">
                        <span className="as_toggle" >
                            <img src="assets/images/svg/menu.svg" alt="" />
                        </span>
                        <div className="as_menu"  style={{ fontSize:'2rem',}}>
                            <ul>
                                <li style={{color:'black',fontWeight:'bold'}}><a href="/">Home</a></li>
                                <li style={{color:'black', fontWeight:'bold'}}><a href="/about">About</a></li>
                                <li style={{color:'black', fontWeight:'bold'}}><a href="/service">services</a></li>
                                <li style={{color:'black', fontWeight:'bold'}}><a href="/shop">shop</a></li>
                                
                                <li style={{color:'black', fontWeight:'bold'}}><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <span className="as_body_overlay" ></span>
                </div>
            </section>

        </>
    )
}

export default Header