import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Shop.css'; 
import b1 from '../images/b1.jpeg';
import b2 from '../images/b2.jpeg';
import b3 from '../images/b3.jpeg';
import b4 from '../images/b4.jpeg';
import b5 from '../images/b5.jpeg';
import b6 from '../images/b6.jpeg';
import b7 from '../images/b7.jpeg';

const Shop = () => {
    const [products, setProducts] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedBracelet, setSelectedBracelet] = useState(null);
    const [formData, setFormData] = useState({ name: '', birthdate: '', birthtime: '', birthplace: '' });
    const navigate = useNavigate();

    // Function to fetch products from backend
    const fetchProducts = async () => {
        try {
            const response = await fetch('https://api.astrodarshan14.com/api/allproducts');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    // Fetch products on component mount
    useEffect(() => {
        fetchProducts();
    }, []);

    // Function to handle product checkout
    const handleCheckout = (product) => {
        navigate('/checkout', { state: { product } });
    };

    // Function to handle form submission for bracelets
    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Encode form data for URL
        const message = `Name: ${formData.name}\nBirthdate: ${formData.birthdate}\nBirthtime: ${formData.birthtime}\nBirthplace: ${formData.birthplace}`;
        const encodedMessage = encodeURIComponent(message);

        // WhatsApp link with encoded message
        const whatsappUrl = `https://wa.me/919226341599?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
        setShowForm(false);
    };


    // Bracelet data (static for now)
    const bracelets = [
        { id: 1, name: 'Customized Bracelet', price: 900, image: b1 },
        { id: 2, name: 'Clear Quartz', price: 800, image: b2 },
        { id: 3, name: 'Sulemani Hakik', price: 800, image: b7 },
        { id: 4, name: 'Pyrite', price: 800, image: b4 },
        { id: 5, name: 'Rose quartz', price: 800, image: b5 },
        { id: 6, name: 'Tiger Eye', price: 800, image: b6 },
        { id: 7, name: 'Amethyst', price: 800, image: b3 },
    ];

    // Function to show form modal over the first bracelet only
    const openForm = (bracelet) => {
        if (bracelet.id === 1) {
            setSelectedBracelet(bracelet);
            setShowForm(true);
        } else {
            handleCheckout(bracelet);
        }
    };

    return (
        <>
            <div className="as_main_wrapper" >
                <section >
                    <div className="container" >
                        <div className="row">
                            <div style={{ textAlign: "center" }}>
                                <h1 className="as_heading as_heading_center" style={{ color: 'black', marginTop: '5vw', fontWeight: 'bold' }}>Bracelets</h1>
                                <p className="as_font14 as_margin0 as_padderBottom20" style={{ color: 'black' }}>Beautiful gemstone bracelets available for purchase.</p>
                            </div>

                            {bracelets.map((bracelet) => (
                                <div key={bracelet.id} className="col-lg-4 col-md-6 col-sm-6 position-relative">
                                    <div className="as_product_box">
                                        <div className="as_product_img">
                                            <img src={bracelet.image} alt={bracelet.name} className="img-responsive" style={{ height: '50vh', objectFit: 'cover' }} />
                                        </div>
                                        <div>
                                            <h4 className="as_subheading" style={{ color: 'black' }}>{bracelet.name}</h4>
                                            <span className="as_price" style={{ color: 'black' }}>₹{bracelet.price}</span>
                                        </div>
                                        <button
                                            className="as_btn"
                                            style={{ marginTop: "15px", color: 'black' }}
                                            onClick={() => openForm(bracelet)}
                                        >
                                            Buy Now
                                        </button>
                                    </div>

                                    {/* Form Modal */}
                                    {showForm && selectedBracelet?.id === bracelet.id && (
                                        <div className="form_modal popup">
                                            <div className="form_container">
                                                <h2 style={{ color: 'black' }}>Enter Your Details</h2>
                                                <form onSubmit={handleFormSubmit}>
                                                    <input
                                                        type="text"
                                                        placeholder="Name"
                                                        value={formData.name}
                                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                        required
                                                    />
                                                    <input
                                                        type="date"
                                                        placeholder="Birthdate"
                                                        value={formData.birthdate}
                                                        onChange={(e) => setFormData({ ...formData, birthdate: e.target.value })}
                                                        required
                                                    />
                                                    <input
                                                        type="time"
                                                        placeholder="Birthtime"
                                                        value={formData.birthtime}
                                                        onChange={(e) => setFormData({ ...formData, birthtime: e.target.value })}
                                                        required
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Birthplace"
                                                        value={formData.birthplace}
                                                        onChange={(e) => setFormData({ ...formData, birthplace: e.target.value })}
                                                        required
                                                    />
                                                    <button type="submit" className="button">Submit</button>
                                                    <button type="button" className="button close-button" onClick={() => setShowForm(false)}>Close</button>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/* Gems Section */}
                <section>
                    <div className="container">
                        <div className="row">
                            <div style={{ textAlign: "center" }}>
                                <h1 className="as_heading as_heading_center" style={{ color: 'black', marginTop: '10px', fontWeight: 'bold' }}>Gems</h1>
                                <p className="as_font14 as_margin0 as_padderBottom20" style={{ color: 'black' }}>We have authentic gemstones available for purchase.</p>
                            </div>

                            {products.length > 0 ? (
                                products.map((product) => (
                                    <div key={product.id} className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="as_product_box">
                                            <div className="as_product_img">
                                                <img src={`https://api.astrodarshan14.com/${product.image_url}`} alt={product.name} className="img-responsive" style={{ height: '30vh', width:'30vh', objectFit: 'cover' }} />
                                            </div>
                                            <div>
                                                <h4 className="as_subheading" style={{ color: 'black' }}>{product.name}</h4>
                                                <p style={{ color: 'black' }}>{product.description}</p>
                                                <span className="as_price" style={{ color: 'black' }}>₹{product.price}</span>
                                            </div>
                                            <button className="as_btn" style={{ marginTop: "15px", color: 'black',marginBottom:'5vw'}} onClick={() => handleCheckout(product)}>Buy Now</button>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Loading products...</p>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Shop;
