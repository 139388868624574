import React, { useState } from 'react';
import axios from 'axios';
import CommonTitle from '../components/CommonTitle';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const apiUrl = 'https://api.astrodarshan14.com/api/addenquiry';
        const enquiryData = { name, email, message };
    
        try {
            const response = await axios.post(apiUrl, enquiryData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            // Check for 200 status and a message in response
            if (response.status === 200 && response.data.message) {
                setStatus(response.data.message);  // Display backend success message
                setName('');
                setEmail('');
                setMessage('');
            } else {
                setStatus("Enquiry Submitted successfully.");
            }
        } catch (error) {
            console.error("Error submitting enquiry:", error);
            setStatus("An error occurred. Please try again.");
        }
    };
    
    function handleMailto() {
        window.location.href =
            "mailto:krishnajoahi7274@gmail.com?subject=Your%20Subject%20Here&body=Your%20message%20here.";
    }

    function handleCallNow() {
        const phoneNumber = "+919226341599";
        window.location.href = `tel:${phoneNumber}`;
    }

    return (
        <>
           
            <section className="as_padderTop80" style={{backgroundColor:''}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="as_contact_info">
                                <h1 className="as_heading" style={{color:'black'}}>Contact Information</h1>
                                <p className="as_font14 as_margin0" style={{color:'black'}}>Please contact us for more information or to avail of our services.</p>

                                <div className="row">
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                        <div className="as_info_box">
                                            <span className="as_icon"style={{backgroundColor:'#ed836b'}}><img src="assets/images/svg/headphone.svg" alt="Phone Icon" /></span>
                                            <div className="as_info">
                                                <h5 style={{color:'black'}}>Call Us</h5>
                                                <p className="as_margin0 as_font14" onClick={handleCallNow} style={{color:'black'}}>+919226341599</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-sm-6 col-xs-12">
                                        <div className="as_info_box">
                                            <span className="as_icon" style={{backgroundColor:'#ed836b'}}><img src="assets/images/svg/mail2.svg" alt="Mail Icon" /></span>
                                            <div className="as_info">
                                                <h5 style={{color:'black'}}>Email Us</h5>
                                                <p className="as_margin0 as_font14"><a onClick={handleMailto} style={{color:'black'}}> astrodarshan@gmail.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="as_contact_form" style={{backgroundColor:'#ffcf63'}}>
                                <h4 className="as_subheading" style={{color:'black'}}>Have a question? Contact us</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label style={{ color: 'white' }} >Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            style={{backgroundColor:'white',color:'black'}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label style={{ color: 'white' }}>Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={{backgroundColor:'white',color:'black'}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label style={{ color: 'white' }}>Message</label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="form-control"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            style={{backgroundColor:'white',color:'black'}}
                                        ></textarea>
                                    </div>
                                    <button type='submit' className="as_btn">Send</button>
                                </form>
                                {status && <p style={{ color: 'green', marginTop: '10px' }}>{status}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="as_map_section">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.9253599109547!2d75.5582288747135!3d20.995628888916798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90f6b2bb24a53%3A0x2a34fe7f370c3434!2sSuman%20residency!5e0!3m2!1sen!2sin!4v1729850978054!5m2!1sen!2sin"
                    width="100%" height="443px" allowFullScreen="" loading="lazy"></iframe>
            </section>
        </>
    )
}

export default Contact;
