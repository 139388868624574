import React from 'react'


const WhyChoose = () => {
    return (
        <>
            <section className="as_whychoose_wrapper as_padderTop80 as_padderBottom50">
                <div className="container">
                    <div className="row as_verticle_center">
                        <div className="col-lg-3 col-md-12">
                            <h1 className="as_heading" style={{color:'black',fontWeight:'bold'}}>Why Choose Us</h1>
                            <p className="as_font14 as_margin0" style={{color:'black',fontWeight:'bold'}}> A trusted astrologer who provides simple solutions and guidance for future challenges...</p>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="row">
                                                              
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="as_whychoose_box text-center">
                                        <span className="as_number">
                                            <span><span data-from="0" data-to="1000" data-speed="5000">1000</span>+</span>
                                            <img src="assets/images/svg/shape.svg" alt="" />
                                        </span>
                                        <h4 style={{color:'black',fontWeight:'bold'}}>Thounsand Trusted Customers</h4>
                                    </div>
                                </div>
                            
                                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                    <div className="as_whychoose_box text-center">
                                        <span className="as_number">
                                            <span><span data-from="0" data-to="25" data-speed="5000">25</span>+</span>
                                            <img src="assets/images/svg/shape.svg" alt="" />
                                        </span>
                                        <h4 style={{color:'black',fontWeight:'bold'}}>Years of Experience</h4>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChoose
