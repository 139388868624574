import React from 'react';
import CommonTitle from '../components/CommonTitle';
import WhatCustomer from '../components/WhatCustomer';
import WhyChoose from '../components/WhyChoose';
import { FaPhone, FaPhoneAlt, FaPhoneSquareAlt } from 'react-icons/fa'

const About = () => {
    return (
        <>
            <div className="as_main_wrapper" style={{marginBottom:'10vh'}}>
            
                <section className="as_about_wrapper as_padderTop80 as_padderBottom80">
    <div className="container" >
        <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="as_about_slider">
                    <div>
                        <div className="as_aboutimg text-right">
                            <img 
                                src="assets/images/about1.jpg" 
                                alt="" 
                                className="img-responsive about-img" 
                                style={{ width: '70%', height: '50%', maxWidth: '100%'  }} // Ensure responsive image
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <h1 className="as_heading">Know about Astrology</h1>
                <p>Astrology is an ancient science that studies the influence of celestial bodies on human life and the natural world. Rooted in centuries-old knowledge, astrology connects the positions of planets, stars, and other cosmic forces with events on Earth, giving us insights into personality traits, relationships, health, and even career paths.</p>
                <p>Unlock the mysteries of the universe and uncover your unique potential through the art and science of astrology. At Astrodarshan, we blend ancient wisdom with modern insight, guiding you to understand your life’s journey, relationships, career, and inner self through the stars and planets.Whether you seek guidance on relationships, career, or personal growth, we are here to help you find clarity and direction.
                </p>
                <p>Astrology doesn’t predict exact events but provides a deeper understanding of personal strengths, weaknesses, and potential life paths. By studying planetary alignments and transits, astrologers offer guidance on making decisions, building relationships, and navigating life changes.</p>
                <a href="https://wa.link/1nof2c" className="as_btn">Know More</a>

               

            </div>
        </div>
    </div>

    <style jsx>{`
        .as_aboutimg {
            text-align: right; 
        }
       
        .as_contact_expert {
            flex-direction: column; /* Stack icon and text vertically */
            align-items: center; /* Center items vertically */
            margin-left: 0; /* Reset margin for mobile */
            justify-content: center; /* Center items horizontally */
        }
        .as_icon {
            margin-bottom: 10px; /* Space below the icon */
            margin-left: 10px; /* Reset left margin */
            
        }
        div > div {
            margin-left: 0; /* Remove left margin for mobile */
           
        }
    }
    `}</style>
</section>
              
            
            </div>
        </>
    );
}

export default About;
