import React from 'react';
import test1 from "../images/testi1.jpg";
import test2 from "../images/testi2.jpg";
import test3 from "../images/testi3.jpg";
import test4 from "../images/testi4.jpg";

const CustomerFeedback = () => {
    return (
        <>
            <section className="as_customer_wrapper as_padderBottom80 as_padderTop80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1 className="as_heading as_heading_center" style={{color:'black',fontWeight:'bold'}}>What Our Customers Say</h1>
                            <p className="as_font14 as_margin0 as_padderBottom50" style={{color:'black'}}>
                                Astrologer - A trusted astrologer providing simple solutions and guidance for future challenges.
                            </p>

                            <div className="row as_customer_slider">
                                <div className="col-lg-6 col-md-6">
                                    <div className="as_customer_box text-center">
                                        <span className="as_customer_img">
                                            <img src={test1} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p className="as_margin0">
                                            The service provided by Astrologer was outstanding. He completed all our projects on time and with high quality.
                                        </p>
                                        <h3>Amit Deshmukh</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="as_customer_box text-center">
                                        <span className="as_customer_img">
                                            <img src={test2} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p className="as_margin0">
                                            Astrologer is truly a skilled engineer. His guidance has greatly benefited our company.
                                        </p>
                                        <h3>Priya Patil</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="as_customer_box text-center">
                                        <span className="as_customer_img">
                                            <img src={test3} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p className="as_margin0">
                                            Working with Astrologer was a very pleasant experience. His attention to detail and honesty is especially commendable.
                                        </p>
                                        <h3>Sandeep Shinde</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="as_customer_box text-center">
                                        <span className="as_customer_img">
                                            <img src={test4} alt="" />
                                            <span><img src="assets/images/svg/quote1.svg" alt="" /></span>
                                        </span>
                                        <p className="as_margin0">
                                            The services of Astrologer are very reliable. The solutions he provided helped us succeed in our work.
                                        </p>
                                        <h3>Rahul Sawant</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CustomerFeedback;
