import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { load } from '@cashfreepayments/cashfree-js'; // Correct import
import './Checkout.css';

const Checkout = () => {
    const location = useLocation();
    const { product } = location.state || {};
    const [sessionId, setSessionId] = useState(null);
    const [cashfree, setCashfree] = useState(null); // Store initialized SDK

    // Initialize Cashfree SDK on component mount
    useEffect(() => {
        const initializeSDK = async () => {
            try {
                const cf = await load({
                    mode: 'production', // or 'test' based on your environment
                });
                console.log('Cashfree SDK initialized:', cf);
                setCashfree(cf); // Set initialized SDK
            } catch (error) {
                console.error('Error loading Cashfree SDK:', error);
                alert('Failed to load Cashfree SDK. Please try again later.');
            }
        };

        initializeSDK();
    }, []);

    // Function to create an order and get session ID
    const createOrder = async (e) => {
        e.preventDefault();

        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const contact = document.getElementById('contact').value;
        const address = {
            address_line1: document.getElementById('address_line1').value,
            address_line2: document.getElementById('address_line2').value,
            city: document.getElementById('city').value,
            state: document.getElementById('state').value,
            postal_code: document.getElementById('postal_code').value,
            country: document.getElementById('country').value,
        };

        try {
            console.log('Sending order data:', { name, email, contact, address, productId: product.id });

            const orderResponse = await axios.post('https://api.astrodarshan14.com/api/addorder', {
                name,
                email,
                contact,
                address,
                productId: product.id,
            });

            console.log('Order response:', orderResponse.data);

            // Check for payment link instead of session_id
            if (orderResponse.data && orderResponse.data.payment_link) {
                setSessionId(orderResponse.data.payment_link); // Set the payment link
                alert('Order created! Click "Pay Now" to proceed with payment.');
            } else {
                alert('Failed to create order');
            }
        } catch (error) {
            console.error('Error creating order:', error.response ? error.response.data : error);
            alert('An error occurred while creating the order. Please try again later.');
        }
    };

    const doPayment = async () => {
        if (sessionId) {
            console.log('Session ID found:', sessionId);
            let checkoutOptions = {
                paymentSessionId: sessionId,
                redirectTarget: "_self",
            };
            console.log('Initiating payment with options:', checkoutOptions);

            try {
                await cashfree.checkout(checkoutOptions);
                console.log('Checkout process initiated successfully.');
            } catch (error) {
                console.error('Error during checkout:', error);
                alert('Payment could not be processed. Please check your payment details.');
            }
        } else {
            alert('No session ID found. Please create an order first.');
        }
    };

    return (
        <div className="checkout-container">
            <h1 className="checkout-title" style={{ color: 'white' }}>Checkout</h1>
            {product ? (
                <div className="checkout-box">
                    <div className="product-summary">
                        <h3>{product.name}</h3>
                        <p style={{ color: 'black' }}>Price: <span className="price">₹{product.price}</span></p>
                    </div>
                    <form className="checkout-form" onSubmit={createOrder}>
                        {/* Form fields */}
                        <div className="form-group">
                            <label htmlFor="name">Full Name</label>
                            <input type="text" id="name" placeholder="Enter your full name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input type="email" id="email" placeholder="Enter your email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contact">Contact Number</label>
                            <input type="text" id="contact" placeholder="Enter your contact number" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address_line1">Address Line 1</label>
                            <input type="text" id="address_line1" placeholder="Enter your address" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address_line2">Address Line 2 (optional)</label>
                            <input type="text" id="address_line2" placeholder="Enter your address" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input type="text" id="city" placeholder="Enter your city" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state">State</label>
                            <input type="text" id="state" placeholder="Enter your state" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="postal_code">Postal Code</label>
                            <input type="text" id="postal_code" placeholder="Enter your postal code" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="country">Country</label>
                            <input type="text" id="country" placeholder="Enter your country" required />
                        </div>

                        <button type="submit" className="add-order-button">Add Order</button>
                    </form>

                    {sessionId && (
                        <button className="pay-now-button" onClick={doPayment}>
                            Pay Now
                        </button>
                    )}
                </div>
            ) : (
                <p>No product selected</p>
            )}
        </div>
    );
};

export default Checkout;
