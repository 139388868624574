import React from 'react'

const CommonTitle = ({ title }) => {
    return (
        <section style={{backgroundColor:'white',marginTop:'10px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1 style={{color:'black',fontWeight:'bold'}}>{title}</h1>
                       
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CommonTitle